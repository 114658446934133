import { gql, useMutation, useQuery } from '@apollo/client'
import { Button, Col, Form, Input, Row, Typography, message } from 'antd'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'

import FullScreenSpin from '../components/FullScreenSpin'
import { CheckIsLoggedInWhenInLoginPageQuery, CheckIsLoggedInWhenInLoginPageQueryVariables, LoginMutation, LoginMutationVariables } from '../types/types'

const loginMutation = gql`
  mutation Login($input: AccountInput!) {
    login(input: $input) {
      data {
        id
        username
      }
      message
      success
    }
  }
`

const checkIsLoggedInQuery = gql`
  query CheckIsLoggedInWhenInLoginPage {
    checkIsLoggedIn {
      message
      success
    }
  }
`

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 }
}

const tailLayout = {
  wrapperCol: { offset: 8, span: 8 }
}

type LoginForm = {
  username: string
  password: string
}

const Login = () => {
  const [form] = Form.useForm<LoginForm>()
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [login] = useMutation<LoginMutation, LoginMutationVariables>(loginMutation)
  const checkIsLoggedInResult = useQuery<CheckIsLoggedInWhenInLoginPageQuery, CheckIsLoggedInWhenInLoginPageQueryVariables>(checkIsLoggedInQuery, {
    fetchPolicy: 'network-only'
  })

  const handleSubmit = async () => {
    try {
      setErrorMessage('')
      const fieldsValue = await form.validateFields()
      setIsLoading(true)
      await login({
        variables: {
          input: fieldsValue as LoginForm
        },
        update: async (_cache, { data }) => {
          if (data?.login?.message) {
            message.error(data.login.message)
            setErrorMessage(data.login.message)
          } else if (data?.login?.success) {
            setErrorMessage('')
            checkIsLoggedInResult.refetch()
          }
          setIsLoading(false)
        }
      })
    } catch (e) {
      if (e.errorFields) {
        form.scrollToField(e.errorFields[0].name)
      }

      if (e.message) {
        message.error(e.message)
      }
    }
  }

  const handleLoginButtonClick = () => {
    handleSubmit()
  }

  const handlePasswordEnterPress = () => {
    handleSubmit()
  }

  const isLoggedIn = !!checkIsLoggedInResult.data?.checkIsLoggedIn?.success

  useEffect(() => {
    if (!checkIsLoggedInResult.loading && isLoggedIn) {
      navigate('/dashboard', {
        replace: true
      })
    }
  }, [checkIsLoggedInResult.loading, isLoggedIn])

  if (checkIsLoggedInResult.loading || isLoggedIn) {
    return <FullScreenSpin />
  }

  const textType = isLoading ? 'secondary' : 'danger'
  const text = isLoading ? '載入中...' : errorMessage

  return (
    <>
      <Row justify='center'>
        <Typography.Title>日不落官網後台</Typography.Title>
      </Row>
      <Form {...formLayout} form={form}>
        <Form.Item label='使用者名稱' name='username' required rules={[{ required: true, message: '請填入使用者名稱' }]}>
          <Input />
        </Form.Item>
        <Form.Item label='密碼' name='password' required rules={[{ required: true, message: '請填入密碼' }]}>
          <Input.Password onPressEnter={handlePasswordEnterPress} type='password' />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button onClick={handleLoginButtonClick} type='primary'>
            登入
          </Button>
        </Form.Item>
        <Row>
          <Col offset={8} span={8}>
            <Typography.Text type={textType}>{text}</Typography.Text>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default Login
